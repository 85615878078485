export const themeRed = {
  variant: "red",
  primary: "#c40511",
  primaryRgb: "196, 5, 17",
  primaryText: "#fff",
  primaryGradient: "#91030c",
  primaryGradientRgb: "145, 3, 12",
  primaryHightlight: "#fde73f",
  secondary: "#404040",
  secondaryText: "rgba(255,255,255,1)",
  tertiary: "rgba(255, 255, 255, 1)",
  tertiaryText: "rgba(255, 255, 255, 0.5)",
  //new addition to compensate wap-t2
  appbar: "linear-gradient(to top, #91030c, #c40511)",
  buttonBackground: "#fff",
  buttonText: "#fff",
  buttonText2: "#000",
  bottomnav: "#f5f5f5",
  bottomNavAction: "#404040",
  body: "#F0EFF5",
  bodyBackground: "#F0EFF5",
  accentText: "#fde73f",
  loginRegisterBtn: "#1b1d33",
  loginRegisterBtnText: "#fff",
};

export const themeBlue = {
  variant: "blue",
  primary: "#1b86c7",
  primaryRgb: "27, 134, 199",
  primaryText: "#fff",
  primaryGradient: "#044BA3",
  primaryGradientRgb: "4, 75, 163",
  primaryHightlight: "#fde73f",
  secondary: "#404040",
  secondaryText: "rgba(255,255,255,1)",
  tertiary: "rgba(255, 255, 255, 1)",
  tertiaryText: "rgba(255, 255, 255, 0.5)",
  //new addition to compensate wap-t2
  appbar: "linear-gradient(to top,#044BA3, #1b86c7)",
  buttonBackground: "#fff",
  buttonText: "#fff",
  buttonText2: "#000",
  bottomnav: "#f5f5f5",
  bottomNavAction: "#404040",
  body: "#F0EFF5",
  bodyBackground: "#F0EFF5",
  accentText: "#fde73f",
  loginRegisterBtn: "#1b1d33",
  loginRegisterBtnText: "#fff",
};

export const themeBrown = {
  variant: "brown",
  primary: "rgb(199, 168, 124)",
  primaryRgb: "199, 168, 124",
  primaryText: "#fff",
  primaryGradient: "rgb(199, 168, 124)",
  primaryGradientRgb: "199, 168, 124",
  primaryHightlight: "rgb(199, 168, 124)",
  secondary: "#404040",
  secondaryText: "rgba(255,255,255,1)",
  tertiary: "rgba(255, 255, 255, 1)",
  tertiaryText: "rgba(255, 255, 255, 0.5)",
  //new addition to compensate wap-t2
  appbar: "rgb(199, 168, 124)",
  buttonBackground: "#fff",
  buttonText: "#fff",
  buttonText2: "#000",
  bottomnav: "#f5f5f5",
  bottomNavAction: "#404040",
  body: "#F0EFF5",
  bodyBackground: "#F0EFF5",
  accentText: "#fff",
  loginRegisterBtn: "rgb(240, 202, 131)",
  loginRegisterBtnText: "#795548",

  popoverBackground: "rgba(255,255,255,0.95)",
  popoverGameTypeText: "rgb(199, 168, 124)",
  popoverSubWalletText: "#000",
};

export const themeKING8 = {
  variant: "brown",
  primary: "rgb(199, 168, 124)",
  primaryRgb: "199, 168, 124",
  primaryText: "#fff",
  primaryGradient: "rgb(199, 168, 124)",
  primaryGradientRgb: "199, 168, 124",
  primaryHightlight: "rgb(199, 168, 124)",
  secondary: "#404040",
  secondaryText: "rgba(255,255,255,1)",
  tertiary: "rgba(255, 255, 255, 1)",
  tertiaryText: "rgba(255, 255, 255, 0.5)",
  //new addition to compensate wap-t2
  appbar: "#000",
  pcAppBar: "#000",
  buttonBackground: "#fff",
  buttonText: "#fff",
  buttonText2: "#000",
  bottomnav: "#f5f5f5",
  bottomNavAction: "#404040",
  body: "#F0EFF5",
  bodyBackground: "#F0EFF5",
  accentText: "#fff",
  loginRegisterBtn: "rgb(240, 202, 131)",
  loginRegisterBtnText: "#795548",

  popoverBackground: "rgba(255,255,255,0.95)",
  popoverGameTypeText: "rgb(199, 168, 124)",
  popoverSubWalletText: "#000",
};

export const BlackGold = {
  variant: "black gold",
  primary: "rgb(0, 0, 0)",
  primaryRgb: "0, 0, 0",
  primaryText: "#fff",
  accentText: "#FFE3A8",
  primaryGradient: "rgb(0, 0, 0)",
  primaryGradientRgb: "0, 0, 0",
  primaryHightlight: "rgb(0, 0, 0)",
  secondary: "#404040",
  secondaryText: "rgba(255,255,255,1)",
  tertiary: "rgba(255, 255, 255, 1)",
  tertiaryText: "rgba(255, 255, 255, 0.5)",
  //new addition to compensate wap-t2
  appbar: "rgb(0,0,0)",
  buttonBackground: "#fff",
  buttonText: "#fff",
  buttonText2: "#000",
  bottomnav: "#000",
  bottomNavAction: "#404040",
  body: "#000",
  bodyBackground: "#F0EFF5",

  loginRegisterBtn: "rgb(240, 202, 131)",
  loginRegisterBtnText: "#795548",

  popoverBackground: "rgba(255,255,255,0.95)",
  popoverGameTypeText: "rgb(199, 168, 124)",
  popoverSubWalletText: "#000",

  new_bodyColor: "#000",
  new_StandardizedBodyColor: true,
  new_altButtonColor: "rgb(85, 85, 85)",
  new_bannerButtonColor: "orange",
  new_gradientBackground: "linear-gradient(to bottom, #FFDF9C, #D3B060)",
  new_gradientBackground1: "linear-gradient(180deg, #3c3c3c 0%, #101010 100%) ",
  new_gradientBackground2: "linear-gradient(to bottom, #FFDF9C, #D3B060)",
  new_iconSet: 1,
  new_primary: "#FFE3A8",
  new_loginBg: "rgb(26, 26, 26)",
  new_inactiveText: "rgba(255,255,255,0.2)",
  new_textfieldBackground: "rgb(85, 85, 85)",
  new_textfieldPlaceholder: "rgba(255,255,255,0.5)",
  new_languageUseDropdown: true,
  new_textColor: "#fff",
  new_wapBackground: "rgb(38 38 38)",
  new_accent: "rgb(0, 173, 239)",
  wapPrimary: "#FFDF9C",
  new_textColor_detail: "#000"
};

export const themeList = {
  default: themeBrown,
  red: themeRed,
  blue: themeBlue,
  themeKING8: themeKING8,
  themeSOWIN: themeKING8,
  blackgold: BlackGold,
};
