import { ApiService } from "./ApiService";
import config from "../../config/config";
import i18n from "../../utils/i18n";
export const GameService = {
  GetGameList,
  JoinGame,
  JoinLobbyGame,
  GetHomePageGameList,
  GetGamePlatformByType,

  SetFavoriteGame,
  RemoveFavoriteGame,
  GetFavoriteGame,
  JoinBetConstruct,
  GetCockfight,
};

function GetGameList(gpCode, gametypes) {
  const result = ApiService.get(
    `Game/list?gpcode=${gpCode}&platformtype=${config.isPC}&language=${i18n.language}&currency=${config.currency}&gametypes=${gametypes}`
  );
  return result;
}

function GetCockfight(gametypes) {
  const result = ApiService.get(`game/sm01`);
  return result;
}

function GetHomePageGameList() {
  const result = ApiService.get(
    `GameProvider/list?platformtype=${config.isPC}&language=${i18n.language}&currency=${config.currency}`
  );
  return result;
}
const languageFromLocalStorage = localStorage.getItem("i18nextLng");
var detectLanguage =
  languageFromLocalStorage || i18n.language || config.defaultLanguage;

//custom handler
const checkIfObject = (obj) => {
  console.log(obj);
  return typeof obj === "object" ? obj?.match.params?.params : obj;
};

function JoinLobbyGame(gtype, gpcode) {
  const result = ApiService.post(`Game/lobby/geturl`, {
    gpcode: checkIfObject(gpcode),
    gtype: parseInt(gtype),
    language: detectLanguage,
    extends: {
      lobbyurl: window.location.protocol + "//" + window.location.hostname,
    },
  });
  return result;
}

function JoinGame(gid, gpcode) {
  const result = ApiService.post(`Game/geturl`, {
    gpcode: gpcode,
    gid: parseInt(gid),
    language: detectLanguage,
    extends: {
      lobbyurl: window.location.protocol + "//" + window.location.hostname,
    },
  });
  return result;
}

function GetGamePlatformByType() {
  const result = ApiService.get(
    `GameProvider/list?platformtype=${config.isPC}&language=${i18n.language}&currency=${config.currency}`
  );
  return result;
}

function SetFavoriteGame(gameId) {
  var result = ApiService.put(`game/favorite/${gameId}`);
  return result;
}
function RemoveFavoriteGame(gameId) {
  var result = ApiService.del(`game/favorite/${gameId}`, {});
  return result;
}
function GetFavoriteGame(gameType) {
  const result = ApiService.get(`game/favorite/${gameType}`);
  return result;
}
function JoinBetConstruct() {
  const result = ApiService.get(`game/bc`);
  return result;
}
