import { ApiService } from "./ApiService";
import config from "../../config/config";

export const GeneralService = {
  GetSiteToken,
  GetPromotionList,
  GetPlayerPromotionList,
  JoinPromotion,
  GetBrandCommunication,
  GetLocalization,
  GetLiveChatURL,
  GetBrandLanguage,
  GetBanner,
  GetBrand,
  GetJoinedPromoReport,
  GetVIP,
  GetInvitationCode,
  GetPromotionRouletteStatus,
  GetPromotionRouletteResult,
  GetPromotion,
  GetPromotionRouletteCount,
  GetDepositAccumulateRoulette,
  GetDepositAccumulateRouletteResult,
  GetDepositAccumulateRouletteHistory,
  GetBrandAllAgent,
  GetAffiliateStatus,
  GetMemoV2,
  ReplyMemo,
  ComposeMemo,
  ReadMemo,
  SentAction,
  GetBrandSocial,
};

function GetSiteToken() {
  const result = ApiService.post("Auth/jwt", {
    client: config.client,
    currency: config.currency,
  }); //done
  return result;
}
function GetBrand() {
  const result = ApiService.get("brand"); //done
  return result;
}

function JoinPromotion(id) {
  const result = ApiService.post(`promotion/${id}/join`);

  return result;
}
function GetPromotionList(language, page, rows, desc, sort, all) {
  const result = ApiService.get(
    `promotion/${language}?page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );

  return result;
}
function GetPlayerPromotionList(language, page, rows, desc, sort, all) {
  const result = ApiService.get(
    `playerpromotion/${language}?page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );

  return result;
}

function GetBrandCommunication() {
  const result = ApiService.get(`brandcommunication`);
  return result;
}
function GetLocalization() {
  const result = ApiService.get(`localization`);
  return result;
}

function GetLiveChatURL() {
  const result = ApiService.get(`brandlivechat`);
  return result;
}
function GetBrandLanguage() {
  const result = ApiService.get(`brandlanguage`);
  return result;
}
function GetBanner() {
  const result = ApiService.get(`banner`);
  return result;
}

function GetJoinedPromoReport(From, To, page, rows, desc, sort, all) {
  const result = ApiService.get(
    `playerpromotion/joined?From=${From}&To=${To}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );
  return result;
}

function GetVIP() {
  const result = ApiService.get("vip");
  return result;
}

function GetInvitationCode() {
  const result = ApiService.get("agent");
  return result;
}

function GetPromotionRouletteStatus(id) {
  const result = ApiService.get(`sie/roulette/${id}`);
  return result;
}

function GetPromotionRouletteResult(id) {
  const result = ApiService.post(`sie/roulette/${id}`);
  return result;
}

function GetPromotion() {
  const result = ApiService.get("promotion");
  return result;
}

function GetPromotionRouletteCount() {
  const result = ApiService.get(`promotion/roulette/count`);
  return result;
}

function GetDepositAccumulateRoulette(promotionId) {
  const result = ApiService.get(`dar/setting/${promotionId}`);
  return result;
}

function GetDepositAccumulateRouletteResult(promotionId) {
  const result = ApiService.post(`dar/${promotionId}`);
  return result;
}

function GetDepositAccumulateRouletteHistory(promotionId) {
  const result = ApiService.get(`dar/roulette/${promotionId}`);
  return result;
}

function GetBrandAllAgent(promotionId) {
  const result = ApiService.get(`brandallagent`);
  return result;
}
function GetAffiliateStatus() {
  const result = ApiService.get(`brandallagent/check`);
  return result;
}

function GetMemoV2(letterId, page, row) {
  const endpoint = letterId
    ? `station-letter/${letterId}`
    : `station-letter?page=${page || 1}&rows=${row || 10}`;
  const result = ApiService.get(endpoint);
  return result;
}

function ReplyMemo(letterId, content) {
  const result = ApiService.put(`station-letter/${letterId}:reply`, {
    content,
  });
  return result;
}

function ComposeMemo(subject, content) {
  const result = ApiService.put(`station-letter`, {
    subject,
    content,
  });
  return result;
}

function ReadMemo(letterId) {
  const result = ApiService.patch(`station-letter/${letterId}:read`);
  return result;
}

function SentAction(operation, id, page, row) {
  if (operation === "get") {
    const url = `delivery-box?page=${page}&rows=${row}`;
    return ApiService.get(url);
  }

  if (operation === "read") {
    const url = `delivery-box/${id}`;
    return ApiService.get(url);
  }
}

function GetBrandSocial() {
  const result = ApiService.get("brand/social");
  return result;
}
