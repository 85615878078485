export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const ARTICLE_SUBMITTED = "ARTICLE_SUBMITTED";
export const SETTINGS_SAVED = "SETTINGS_SAVED";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const SETTINGS_PAGE_UNLOADED = "SETTINGS_PAGE_UNLOADED";
export const HOME_PAGE_LOADED = "HOME_PAGE_LOADED";
export const HOME_PAGE_UNLOADED = "HOME_PAGE_UNLOADED";
export const ARTICLE_PAGE_LOADED = "ARTICLE_PAGE_LOADED";
export const ARTICLE_PAGE_UNLOADED = "ARTICLE_PAGE_UNLOADED";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ARTICLE_FAVORITED = "ARTICLE_FAVORITED";
export const ARTICLE_UNFAVORITED = "ARTICLE_UNFAVORITED";
export const SET_PAGE = "SET_PAGE";
export const APPLY_TAG_FILTER = "APPLY_TAG_FILTER";
export const CHANGE_TAB = "CHANGE_TAB";
export const PROFILE_PAGE_LOADED = "PROFILE_PAGE_LOADED";
export const PROFILE_PAGE_UNLOADED = "PROFILE_PAGE_UNLOADED";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED";
export const REGISTER_PAGE_UNLOADED = "REGISTER_PAGE_UNLOADED";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const EDITOR_PAGE_LOADED = "EDITOR_PAGE_LOADED";
export const EDITOR_PAGE_UNLOADED = "EDITOR_PAGE_UNLOADED";
export const ADD_TAG = "ADD_TAG";
export const REMOVE_TAG = "REMOVE_TAG";
export const UPDATE_FIELD_AUTH = "UPDATE_FIELD_AUTH";
export const UPDATE_FIELD_EDITOR = "UPDATE_FIELD_EDITOR";
export const FOLLOW_USER = "FOLLOW_USER";
export const UNFOLLOW_USER = "UNFOLLOW_USER";
export const PROFILE_FAVORITES_PAGE_UNLOADED =
  "PROFILE_FAVORITES_PAGE_UNLOADED";
export const PROFILE_FAVORITES_PAGE_LOADED = "PROFILE_FAVORITES_PAGE_LOADED";

export const UPDATING_SCREEN_SIZE = "UPDATING_SCREEN_SIZE";

export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CHANGE_HOME_PAGE_GAME_TYPE = "CHANGE_HOME_PAGE_GAME_TYPE";
export const SHOW_MESSAGEBOX = "SHOW_MESSAGEBOX";
export const CLOSE_MESSAGEBOX = "CLOSE_MESSAGEBOX";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const SHOW_LOGIN = "SHOW_LOGIN";
export const SHOWING_DIALOG = "SHOWING_DIALOG";
export const UNAUTHORIZE = "UNAUTHORIZE";
export const IPRESTRICT = "IPRESTRICT";
export const UPDATING_JACKPOT = "UPDATING_JACKPOT";

export const GETMOBILEPREFIX_SUCCESS = "GETMOBILEPREFIX_SUCCESS";

export const GETMARQUEE_BEGIN = "GETMARQUEE_BEGIN";
export const GETMARQUEE_SUCCESS = "GETMARQUEE_SUCCESS";
export const GETMARQUEE_FAIL = "GETMARQUEE_FAIL";

export const GETANNOUNCEMENT_BEGIN = "GETANNOUNCEMENT_BEGIN";
export const GETANNOUNCEMENT_SUCCESS = "GETANNOUNCEMENT_SUCCESS";
export const GETANNOUNCEMENT_FAIL = "GETANNOUNCEMENT_FAIL";

export const GETDEPOSITCHANNEL_BEGIN = "GETDEPOSITCHANNEL_BEGIN";
export const GETDEPOSITCHANNEL_SUCCESS = "GETDEPOSITCHANNEL_SUCCESS";
export const GETDEPOSITCHANNEL_FAIL = "GETDEPOSITCHANNEL_FAIL";

export const GETBALANCELIST_BEGIN = "GETBALANCELIST_BEGIN";
export const GETBALANCELIST_SUCCESS = "GETBALANCELIST_SUCCESS";
export const GETBALANCELIST_FAIL = "GETBALANCELIST_FAIL";

export const UPDATEBALANCELIST_BEGIN = "UPDATEBALANCELIST_BEGIN";
export const UPDATEBALANCELIST_SUCCESS = "UPDATEBALANCELIST_SUCCESS";
export const UPDATEBALANCELIST_FAIL = "UPDATEBALANCELIST_FAIL";

export const CHECKGAME_BEGIN = "CHECKGAME_BEGIN";
export const CHECKGAME_SUCCESS = "CHECKGAME_SUCCESS";
export const CHECKGAME_FAIL = "CHECKGAME_FAIL";

export const GETBANKCARDLIST_BEGIN = "GETBANKCARDLIST_BEGIN";
export const GETBANKCARDLIST_SUCCESS = "GETBANKCARDLIST_SUCCESS";
export const GETBANKCARDLIST_FAIL = "GETBANKCARDLIST_FAIL";

export const GETBANKLIST_BEGIN = "GETBANKLIST_BEGIN";
export const GETBANKLIST_SUCCESS = "GETBANKLIST_SUCCESS";
export const GETBANKLIST_FAIL = "GETBANKLIST_FAIL";

export const DELETEBANKCARD_BEGIN = "DELETEBANKCARD_BEGIN";
export const DELETEBANKCARD_SUCCESS = "DELETEBANKCARD_SUCCESS";
export const DELETEBANKCARD_FAIL = "DELETEBANKCARD_FAIL";

export const ADDBANKCARD_BEGIN = "ADDBANKCARD_BEGIN";
export const ADDBANKCARD_SUCCESS = "ADDBANKCARD_SUCCESS";
export const ADDBANKCARD_FAIL = "ADDBANKCARD_FAIL";

export const ADDBILLING_BEGIN = "ADDBILLING_BEGIN";
export const ADDBILLING_SUCCESS = "ADDBILLING_SUCCESS";
export const ADDBILLING_FAIL = "ADDBILLING_FAIL";

export const CANCELDEPOSIT_BEGIN = "CANCELDEPOSIT_BEGIN";
export const CANCELDEPOSIT_SUCCESS = "CALCENDEPOSIT_SUCCESS";
export const CANCELDEPOSIT_FAIL = "CANCELDEPOSIT_FAIL";

export const DOMANUALDEPOSITUPLOAD_BEGIN = "DOMANUALDEPOSITUPLOAD_BEGIN";
export const DOMANUALDEPOSITUPLOAD_SUCCESS = "DOMANUALDEPOSITUPLOAD_SUCCESS";
export const DOMANUALDEPOSITUPLOAD_FAIL = "DOMANUALDEPOSITUPLOAD_FAIL";

export const DOWITHDRAWAL_BEGIN = "DOWITHDRAWAL_BEGIN";
export const DOWITHDRAWAL_SUCCESS = "DOWITHDRAWAL_SUCCESS";
export const DOWITHDRAWAL_FAIL = "DOWITHDRAWAL_FAIL";

export const DOTRANSFER_BEGIN = "DOTRANSFER_BEGIN";
export const DOTRANSFER_SUCCESS = "DOTRANSFER_SUCCESS";
export const DOTRANSFER_FAIL = "DOTRANSFER_FAIL";

export const GETGAMEBALANCE_BEGIN = "GETGAMEBALANCE_BEGIN";
export const GETGAMEBALANCE_SUCCESS = "GETGAMEBALANCE_SUCCESS";
export const GETGAMEBALANCE_FAIL = "GETGAMEBALANCE_FAIL";

export const WALLET_HIDEMESSAGE = "WALLET_HIDEMESSAGE";
export const RESET_WALLETRESULT = "RESET_WALLETRESULT";

export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const GETACCOUNT_SUCCESS = "GETACCOUNT_SUCCESS";
export const GETBALANCE_SUCCESS = "GETBALANCE_SUCCESS";
export const GETACCOUNT_FAILURE = "GETACCOUNT_FAILURE";
export const GETACCOUNT_BEGIN = "GETACCOUNT_BEGIN";
export const CHECKTOKEN_BEGIN = "CHECKTOKEN_BEGIN";
export const CHECKTOKEN_SUCCESS = "CHECKTOKEN_SUCCESS";
export const CHECKTOKEN_FAILURE = "CHECKTOKEN_FAILURE";
export const LOGOUT_BEGIN = "LOGOUT_BEGIN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const GETVIPINFO_SUCCESS = "GETVIPINFO_SUCCESS";
export const GETVIPINFO_FAILURE = "GETVIPINFO_FAILURE";
export const UPDATE_INFO = "UPDATE_INFO";

export const GETIDCARDNO_BEGIN = "GETIDCARDNO_BEGIN";
export const GETIDCARDNO_FAILURE = "GETIDCARDNO_FAILURE";
export const GETIDCARDNO_SUCCESS = "GETIDCARDNO_SUCCESS";

export const UPDATECARDNO_BEGIN = "UPDATECARDNO_BEGIN";
export const UPDATECARDNO_FAILURE = "UPDATECARDNO_FAILURE";
export const UPDATECARDNO_SUCCESS = "UPDATECARDNO_SUCCESS";
export const RESET_ACCOUNTRESULT = "RESET_ACCOUNTRESULT";

export const BONUSNEW_BEGIN = "BONUSNEW_BEGIN";
export const BONUSNEW_SUCCESS = "BONUSNEW_SUCCESS";
export const BONUSNEW_FAILURE = "BONUSNEW_FAILURE";

export const GETPROMOLIST_BEGIN = "GETPROMOLIST_BEGIN";
export const GETPROMOLIST_SUCCESS = "GETPROMOLIST_SUCCESS";
export const GETPROMOLIST_FAILURE = "GETPROMOLIST_FAILURE";

export const GETPROMOTION_BEGIN = "GETPROMOTION_BEGIN";
export const GETPROMOTION_SUCCESS = "GETPROMOTION_SUCCESS";
export const GETPROMOTION_FAILURE = "GETPROMOTION_FAILURE";

export const GETPLAYERPROMOLIST_BEGIN = "GETPLAYERPROMOLIST_BEGIN";
export const GETPLAYERPROMOLIST_SUCCESS = "GETPLAYERPROMOLIST_SUCCESS";
export const GETPLAYERPROMOLIST_FAILURE = "GETPLAYERPROMOLIST_FAILURE";

export const JOINPROMO_BEGIN = "JOINPROMO_BEGIN";
export const JOINPROMO_SUCCESS = "JOINPROMO_SUCCESS";
export const JOINPROMO_FAILURE = "JOINPROMO_FAILURE";

export const DOWITHDRAWALALL_BEGIN = "DOWITHDRAWALALL_BEGIN";
export const DOWITHDRAWALALL_SUCCESS = "DOWITHDRAWALALL_SUCCESS";
export const DOWITHDRAWALALL_FAIL = "DOWITHDRAWALALL_FAIL";

export const KYGAMELIST_SUCCESS = "BONUSNEW_FAILURE";

export const GETHOMEPAGEGAMELIST_BEGIN = "GETHOMEPAGEGAMELIST_BEGIN";
export const GETHOMEPAGEGAMELIST_SUCCESS = "GETHOMEPAGEGAMELIST_SUCCESS";
export const GETHOMEPAGEGAMELIST_FAIL = "GETHOMEPAGEGAMELIST_FAIL";

export const GETINVITELINK_BEGIN = "GETINVITELINK_BEGIN";
export const GETINVITELINK_FAILURE = "GETINVITELINK_FAILURE";
export const GETINVITELINK_SUCCESS = "GETINVITELINK_SUCCESS";
export const GETPROMOTEUSER_SUCCESS = "GETPROMOTEUSER_SUCCESS";
export const GETPROMOTEBILL_SUCCESS = "GETPROMOTEBILL_SUCCESS";

export const GETGAMEPLATFORMLIST_BEGIN = "GETGAMEPLATFORMLIST_BEGIN";
export const GETGAMEPLATFORMLIST_SUCCESS = "GETGAMEPLATFORMLIST_SUCCESS";
export const GETGAMEPLATFORMLIST_FAIL = "GETGAMEPLATFORMLIST_FAIL";

export const GETSITETOKEN_BEGIN = "GETSITETOKEN_BEGIN";
export const GETSITETOKEN_SUCCESS = "GETSITETOKEN_SUCCESS";
export const GETSITETOKEN_FAIL = "GETSITETOKEN_FAIL";

export const GETWALLETBALANCE_BEGIN = "GETWALLETBALANCE_BEGIN";
export const GETWALLETEBALANCE_SUCCESS = "GETWALLETEBALANCE_SUCCESS";
export const GETWALLETBALANCE_FAILED = "GETWALLETBALANCE_FAILED";

export const GETMESSAGE_BEGIN = "GETMESSAGE_BEGIN";
export const GETMESSAGE_SUCCESS = "GETMESSAGE_SUCCESS";
export const GETMESSAGE_FAILED = "GETMESSAGE_FAILED";

export const SETMESSAGEREAD_BEGIN = "SETMESSAGEREAD_BEGIN";
export const SETMESSAGEREAD_SUCCESS = "SETMESSAGEREAD_SUCCESS";
export const SETMESSAGEREAD_FAILED = "SETMESSAGEREAD_FAILED";

export const GETWITHDRAWALCHANNEL_BEGIN = "GETWITHDRAWALCHANNEL_BEGIN";
export const GETWITHDRAWALCHANNEL_SUCCESS = "GETWITHDRAWALCHANNEL_SUCCESS";
export const GETWITHDRAWALCHANNEL_FAILED = "GETWITHDRAWALCHANNEL_FAILED";

export const DOLEEPAYDEPOSIT_BEGIN = "DOLEEPAYDEPOSIT_BEGIN";
export const DOLEEPAYDEPOSIT_SUCCESS = "DOLEEPAYDEPOSIT_SUCCESS";
export const DOLEEPAYDEPOSIT_FAIL = "DOLEEPAYDEPOSIT_FAIL";

export const DOUMPAYDEPOSIT_BEGIN = "DOUMPAYDEPOSIT_BEGIN";
export const DOUMPAYDEPOSIT_SUCCESS = "DOUMPAYDEPOSIT_SUCCESS";
export const DOUMPAYDEPOSIT_FAIL = "DOUMPAYDEPOSIT_FAIL";

export const DOPRIMEPAYDEPOSIT_BEGIN = "DOPRIMEPAYDEPOSIT_BEGIN";
export const DOPRIMEPAYDEPOSIT_SUCCESS = "DOPRIMEPAYDEPOSIT_SUCCESS";
export const DOPRIMEPAYDEPOSIT_FAIL = "DOPRIMEPAYDEPOSIT_FAIL";

export const DOMATICPAYDEPOSIT_BEGIN = "DOMATICPAYDEPOSIT_BEGIN";
export const DOMATICPAYDEPOSIT_SUCCESS = "DOMATICPAYDEPOSIT_SUCCESS";
export const DOMATICPAYDEPOSIT_FAIL = "DOMATICPAYDEPOSIT_FAIL";

export const DOAPPLEPAYDEPOSIT_BEGIN = "DOAPPLEPAYDEPOSIT_BEGIN";
export const DOAPPLEPAYDEPOSIT_SUCCESS = "DOAPPLEPAYDEPOSIT_SUCCESS";
export const DOAPPLEPAYDEPOSIT_FAIL = "DOAPPLEPAYDEPOSIT_FAIL";

export const GETBRANDCOMMUNICATION_BEGIN = "GETBRANDCOMMUNICATION_BEGIN";
export const GETBRANDCOMMUNICATION_SUCCESS = "GETBRANDCOMMUNICATION_SUCCESS";
export const GETBRANDCOMMUNICATION_FAIL = "GETBRANDCOMMUNICATION_FAIL";

export const GETBRANDLANGUAGE_BEGIN = "GETBRANDLANGUAGE_BEGIN";
export const GETBRANDLANGUAGE_SUCCESS = "GETBRANDLANGUAGE_SUCCESS";
export const GETBRANDLANGUAGE_FAIL = "GETBRANDLANGUAGE_FAIL";

export const GETBANNER_BEGIN = "GETBANNER_BEGIN";
export const GETBANNER_SUCCESS = "GETBANNER_SUCCESS";
export const GETBANNER_FAIL = "GETBANNER_FAIL";

export const GETBRAND_BEGIN = "GETBRAND_BEGIN";
export const GETBRAND_SUCCESS = "GETBRAND_SUCCESS";
export const GETBRAND_FAIL = "GETBRAND_FAIL";

export const GETLIVECHATURL_BEGIN = "GETLIVECHATURL_BEGIN";
export const GETLIVECHATURL_SUCCESS = "GETLIVECHATURL_SUCCESS";
export const GETLIVECHATURL_FAIL = "GETLIVECHATURL_FAIL";

export const GETAUTOTRANSFER_BEGIN = "GETAUTOTRANSFER_BEGIN";
export const GETAUTOTRANSFER_SUCCESS = "GETAUTOTRANSFER_SUCCESS";
export const GETAUTOTRANSFER_FAIL = "GETAUTOTRANSFER_FAIL";

export const UPDATEAUTOTRANSFER_BEGIN = "UPDATEAUTOTRANSFER_BEGIN";
export const UPDATEAUTOTRANSFER_SUCCESS = "UPDATEAUTOTRANSFER_SUCCESS";
export const UPDATEAUTOTRANSFER_FAIL = "UPDATEAUTOTRANSFER_FAIL";

export const GETFAVGAME_BEGIN = "GETFAVGAME_BEGIN";
export const GETFAVGAME_SUCCESS = "GETFAVGAME_SUCCESS";
export const GETFAVGAME_FAIL = "GETFAVGAME_FAIL";

export const REMOVEFAVGAME_BEGIN = "REMOVEFAVGAME_BEGIN";
export const REMOVEFAVGAME_SUCCESS = "REMOVEFAVGAME_SUCCESS";
export const REMOVEFAVGAME_FAIL = "REMOVEFAVGAME_FAIL";

export const SETFAVGAME_BEGIN = "SETFAVGAME_BEGIN";
export const SETFAVGAME_SUCCESS = "SETFAVGAME_SUCCESS";
export const SETFAVGAME_FAIL = "SETFAVGAME_FAIL";

export const DOBANKDEPOSIT_BEGIN = "DOBANKDEPOSIT_BEGIN";
export const DOBANKDEPOSIT_SUCCESS = "DOBANKDEPOSIT_SUCCESS";
export const DOBANKDEPOSIT_FAILED = "DOBANKDEPOSIT_FAILED";

export const DOCRYPTODEPOSIT_BEGIN = "DOCRYPTODEPOSIT_BEGIN";
export const DOCRYPTODEPOSIT_SUCCESS = "DOCRYPTODEPOSIT_SUCCESS";
export const DOCRYPTODEPOSIT_FAILED = "DOCRYPTODEPOSIT_FAILED";

export const DOTHIRDPARTYDEPOSIT_BEGIN = "DOTHIRDPARTYDEPOSIT_BEGIN";
export const DOTHIRDPARTYDEPOSIT_SUCCESS = "DOTHIRDPARTYDEPOSIT_SUCCESS";
export const DOTHIRDPARTYDEPOSIT_FAIL = "DOTHIRDPARTYDEPOSIT_FAIL";

export const DOHELP2PAYDEPOSIT_BEGIN = "DOHELP2PAYDEPOSIT_BEGIN";
export const DOHELP2PAYDEPOSIT_SUCCESS = "DOHELP2PAYDEPOSIT_SUCCESS";
export const DOHELP2PAYDEPOSIT_FAILED = "DOHELP2PAYDEPOSIT_FAILED";

export const DOSKYPAYDEPOSIT_BEGIN = "DOSKYPAYDEPOSIT_BEGIN";
export const DOSKYPAYDEPOSIT_SUCCESS = "DOSKYPAYDEPOSIT_SUCCESS";
export const DOSKYPAYDEPOSIT_FAILED = "DOSKYPAYDEPOSIT_FAILED";

export const DOMANUALDEPOSIT_BEGIN = "DOMANUALDEPOSIT_BEGIN";
export const DOMANUALDEPOSIT_SUCCESS = "DOMANUALDEPOSIT_SUCCESS";
export const DOMANUALDEPOSIT_FAILED = "DOMANUALDEPOSIT_FAILED";

export const GETVIP_BEGIN = "GETVIP_BEGIN";
export const GETVIP_SUCCESS = "GETVIP_SUCCESS";
export const GETVIP_FAILED = "GETVIP_FAILED";

export const GETINVITATIONCODE_SUCCESS = "GETINVITATIONCODE_SUCCESS";
export const GETINVITATIONCODE_FAILED = "GETINVITATIONCODE_FAILED";

export const DODEPOSIT_BEGIN = "DODEPOSIT_BEGIN";
export const DODEPOSIT_SUCCESS = "DODEPOSIT_SUCCESS";
export const DODEPOSIT_FAILED = "DODEPOSIT_FAILED";

export const GETPROMOROULETTE_STATUS_BEGIN = "GETPROMOROULETTE_STATUS_BEGIN";
export const GETPROMOROULETTE_STATUS_SUCCESS =
  "GETPROMOROULETTE_STATUS_SUCCESS";
export const GETPROMOROULETTE_STATUS_FAILED = "GETPROMOROULETTE_STATUS_FAILED";

export const GETPROMOROULETTE_RESULT_BEGIN = "GETPROMOROULETTE_RESULT_BEGIN";
export const GETPROMOROULETTE_RESULT_SUCCESS =
  "GETPROMOROULETTE_RESULT_SUCCESS";
export const GETPROMOROULETTE_RESULT_FAILED = "GETPROMOROULETTE_RESULT_FAILED";

export const GETDAROULETTE_STATUS_BEGIN = "GETDAROULETTE_STATUS_BEGIN";
export const GETDAROULETTE_STATUS_SUCCESS = "GETDAROULETTE_STATUS_SUCCESS";
export const GETDAROULETTE_STATUS_FAILED = "GETDAROULETTE_STATUS_FAILED";

export const GETDAROULETTE_RESULT_BEGIN = "GETDAROULETTE_RESULT_BEGIN";
export const GETDAROULETTE_RESULT_SUCCESS = "GETDAROULETTE_RESULT_SUCCESS";
export const GETDAROULETTE_RESULT_FAILED = "GETDAROULETTE_RESULT_FAILED";

export const GETROULETTE_COUNT_BEGIN = "GETROULETTE_COUNT_BEGIN";
export const GETROULETTE_COUNT_SUCCESS = "GETROULETTE_COUNT_SUCCESS";
export const GETROULETTE_COUNT_FAILED = "GETROULETTE_COUNT_FAILED";

export const GETDAROULETTE_HISTORY_BEGIN = "GETDAROULETTE_HISTORY_BEGIN";
export const GETDAROULETTE_HISTORY_SUCCESS = "GETDAROULETTE_HISTORY_SUCCESS";
export const GETDAROULETTE_HISTORY_FAILED = "GETDAROULETTE_HISTORY_FAILED";

export const RESET_PROMOROULETTE = "RESET_PROMOROULETTE";

export const GETBRANDALLAGENT_BEGIN = "GETBRANDALLAGENT_BEGIN";
export const GETBRANDALLAGENT_SUCCESS = "GETBRANDALLAGENT_SUCCESS";
export const GETBRANDALLAGENT_FAIL = "GETBRANDALLAGENT_FAIL";
export const GETAFFILIATESTATUS_SUCCESS = "GETAFFILIATESTATUS_SUCCESS";
export const GETAFFILIATESTATUS_FAIL = "GETAFFILIATESTATUS_FAIL";

export const GETMEMOV2_BEGIN = "GETMEMOV2_BEGIN";
export const GETMEMOV2_SUCCESS = "GETMEMOV2_SUCCESS";
export const GETMEMOV2_FAIL = "GETMEMOV2_FAIL";

export const REPLYMEMO_BEGIN = "REPLYMEMO_BEGIN";
export const REPLYMEMO_SUCCESS = "REPLYMEMO_SUCCESS";
export const REPLYMEMO_FAIL = "REPLYMEMO_FAIL";

export const COMPOSEMEMO_BEGIN = "COMPOSEMEMO_BEGIN";
export const COMPOSEMEMO_SUCCESS = "COMPOSEMEMO_SUCCESS";
export const COMPOSEMEMO_FAIL = "COMPOSEMEMO_FAIL";

export const READMEMO_BEGIN = "READMEMO_BEGIN";
export const READMEMO_SUCCESS = "READMEMO_SUCCESS";
export const READMEMO_FAIL = "READMEMO_FAIL";

export const DELIVERYBOX_BEGIN = "DELIVERYBOX_BEGIN";
export const DELIVERYBOX_SUCCESS = "DELIVERYBOX_SUCCESS";
export const DELIVERYBOX_FAIL = "DELIVERYBOX_FAIL";

export const GETSOCIAL_BEGIN = "GETSOCIAL_BEGIN";
export const GETSOCIAL_SUCCESS = "GETSOCIAL_SUCCESS";
export const GETSOCIAL_FAILED = "GETSOCIAL_FAILED";
