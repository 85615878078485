const featureList = {
  default: {
    SMSVerification: true,
    PhonePrefix: "+852",
    EmailVerification: true,
    ICVerification: true,
    QQWeChat: true,
    SportsHideLogo: false,
    PopupAnnouncement: false,
    Withdrawal_HundredDigit: false,
    Deposit_ManualApplePayHideBankAcc: false,
    Deposit_DualPage: false,
    AddCard_BankBranch: true,
    AddCard_DisplayFPS: false,
    MenuItem_Sorting_HK28: false,
    MenuItem_ShootFish: false,
    HomePage_FirstSection6Widget: false,
    GamePage_SpecificBackground: false,
    Wap_SupportCustomBackground: false,
    HaveDownloadFeature: false,
    FloatPanel_CustomTranslation: false,
    DisplayPhone: false,
    ChangesNotAllowed: false,
    HomePage_CustomSection: false,
    ICVerificationUpload: false,
    Profile_CustomIdentityPageTranslation: false,
    CustomVIP: false,
    Deposit_EnglishName: false,
    HomePageDisplayBalance: false,
    BackgroundMusic: false,
    OnlineUser: true,
    CustomDepositInput: false,
    AddCard_InputAddFPS: false,
    Deposit_RemoveName: true,
    DepositNotification: false,
    AddCard_HideChannel: false,
    AddCard_BankDropdown: false,
    Deposit_AddTips: false,
    Deposit_CustomBankNameTranslation: false,
    AddCard_DisplayGCash: false,
    AddCard_NotAllowedEditDelete: false,
    NavigateToAddCard: false,
    Profile_NotAllowChangePhone: false,
    Deposit_RemoveAccount: false,
    Deposit_AutofillFirstCard: false,
    HomePage_Animated: false,

    AddCard_MaximumCardAllowed: 99,
    AddCard_DisableCreateUpdateDelete: false,
    Register_HideInvitation: false,
    PopupPromotion: false,

    LiveChatVendor: "",

    Home_CustomFPPosition: false,
    CustomMessaging: [],
    Deposit_FPSWithName: false,
    Register_ShowPhone: false,
    MenuItem_Sorting_WLGHK: false,
    MenuItem_Sorting_SKY: false,
    MenuItem_Sorting_PB: false,
    MenuItem_Sorting_GMO: false,
    MenuItem_Sorting_BUNNY: false,
    VIP_CustomLevel_BUNNY: false,
    Withdrawal_Ignore_Verification: false,
    LiveGame_Sorting_SKY: false,
    Report_ShowRoundsTotal: true,
    Profile_SetUpBirthdateOnce: true,
    Deposit_OriginAccount: false,
    Profile_ExistingDetails_ViewOnly: false, // except change password
    Withdrawal_USDT_ExchangeRate: 0.151,
    Profile_DisableEditPhone: true,

    Deposit_UploadRequired: true,
    Deposit_HideDepositChannel: false,
    AddCard_EWalletCustoms: false,

    HomePage_DisplayVIP: true,
    Profile_DisableVerifyPhone: false,
    Deposit_Withdrawal_PlayerNote: false,
    AddCard_CustomParam_IDR: false,

    Download_PointlessDialog: false,
    Register_ShowEmail: false,

    NewTransferUI: false,
    ForceRedirectAddCard: false,
    AddCard_Bank_Custom_IDR: false,
    Deposit_AddCard: false,
    Register_CustomFlow_IDR: false,
    HomePage_PromoRoulette: false,
    PromoRoulette_SMSVerification: false,
    Maintenance_Detail: {
      startTime: "2022-07-12T01:35:00.000Z",
      endTime: "2022-07-12T04:30:00.000Z",
    },
    ForcedVerification: false,
    Profile_DisplayEditNameButton: true,
    Home_OnlineUsers: {
      min: 26500,
      max: 27500,
    },
    MergeEVORTNT: true,
    MenuItem_Sorting_PB3: false,
    Deposit_AccountNameManualInput: false,
    CustomBunnyAffiliate: false,
    WGHK_Setup: false,

    ShowMarquee: false,
    ShowVerificationStatus: true,
    RouletteNewFlow: false,
    InitPhone_WithoutVerify: false,
    HaveVIPFeature: true,
    CustomKING8Deposit: false,
    DepositCustomPaymentNames: [],
    Withdrawal_WalletTransfer: false,
    Deposit_ManualDepositPassPhone: false,
    SendCodeInterval: 60
  },
};
export default featureList;
