const gameList = [
  {
    abbreviation: "365",
    game_platform_id: 0,
    game_platform_name: "365",
    is_try: 2,
    name: "365",
    params: null,
    gpcode: "365",
    type: 8,
    status: 0,
  },
  {
    abbreviation: "365",
    game_platform_id: 0,
    game_platform_name: "365",
    is_try: 2,
    name: "365",
    params: null,
    gpcode: "365",
    type: 7,
    status: 0,
  },
  {
    abbreviation: "BC",
    game_platform_id: 0,
    game_platform_name: "BetConstruct",
    is_try: 2,
    name: "BetConstruct",
    params: null,
    gpcode: "BC",
    type: 1,
    status: 0,
  },
  {
    abbreviation: "AVIA",
    game_platform_id: 0,
    game_platform_name: "AVIA",
    is_try: 2,
    name: "AVIA",
    params: null,
    gpcode: "AVIA",
    type: 4,
    status: 0,
  },
  {
    abbreviation: "JILITF",
    game_platform_id: 0,
    game_platform_name: "JILI",
    is_try: 2,
    name: "JILI",
    params: null,
    gpcode: "JILITF",
    type: 15,
    status: 0,
  },
  {
    abbreviation: "JILITF",
    game_platform_id: 0,
    game_platform_name: "JILI",
    is_try: 2,
    name: "JILI",
    params: null,
    gpcode: "JILITF",
    type: 9,
    status: 0,
  },
  {
    abbreviation: "AWC_PGS",
    game_platform_id: 0,
    game_platform_name: "PGS",
    is_try: 2,
    name: "PGS",
    params: null,
    gpcode: "AWC_PGS",
    type: 15,
    status: 0,
  },
  {
    abbreviation: "AWC_PGS",
    game_platform_id: 0,
    game_platform_name: "PGS",
    is_try: 2,
    name: "PGS",
    params: null,
    gpcode: "AWC_PGS",
    type: 16,
    status: 0,
  },
  {
    abbreviation: "AWC_SG",
    game_platform_id: 0,
    game_platform_name: "SG",
    is_try: 2,
    name: "SG",
    params: null,
    gpcode: "AWC_SG",
    type: 5,
    status: 0,
  },
  {
    abbreviation: "AWC_SG",
    game_platform_id: 0,
    game_platform_name: "SG",
    is_try: 2,
    name: "SG",
    params: null,
    gpcode: "AWC_SG",
    type: 6,
    status: 0,
  },
  {
    abbreviation: "NTTF",
    game_platform_id: 0,
    game_platform_name: "NTTF",
    is_try: 2,
    name: "NTTF",
    params: null,
    gpcode: "NTTF",
    type: 15,
    status: 0,
  },
  {
    abbreviation: "RTTF",
    game_platform_id: 0,
    game_platform_name: "RTTF",
    is_try: 2,
    name: "RTTF",
    params: null,
    gpcode: "RTTF",
    type: 15,
    status: 0,
  },
  {
    abbreviation: "RTTF",
    game_platform_id: 0,
    game_platform_name: "RTTF",
    is_try: 2,
    name: "RTTF",
    params: null,
    gpcode: "RTTF",
    type: 16,
    status: 0,
  },
  {
    abbreviation: "EVOTF",
    game_platform_id: 0,
    game_platform_name: "EVO. RT. NT",
    is_try: 2,
    name: "EVO. RT. NT",
    params: null,
    gpcode: "EVOTF",
    type: 5,
    status: 0,
  },
  {
    abbreviation: "EVOTF",
    game_platform_id: 0,
    game_platform_name: "EVO. RT. NT",
    is_try: 2,
    name: "EVO. RT. NT",
    params: null,
    gpcode: "EVOTF",
    type: 6,
    status: 0,
  },
  {
    abbreviation: "BTI",
    game_platform_id: 0,
    game_platform_name: "BTI",
    is_try: 2,
    name: "BTI",
    params: null,
    gpcode: "BTI",
    type: 1,
    status: 0,
  },
  {
    abbreviation: "PPTF",
    game_platform_id: 0,
    game_platform_name: "PragmaticPlay",
    is_try: 2,
    name: "PragmaticPlay",
    params: null,
    gpcode: "PPTF",
    type: 15,
    status: 0,
  },
  {
    abbreviation: "PPTF",
    game_platform_id: 0,
    game_platform_name: "PragmaticPlay",
    is_try: 2,
    name: "PragmaticPlay",
    params: null,
    gpcode: "PPTF",
    type: 5,
    status: 0,
  },
  {
    abbreviation: "PPTF",
    game_platform_id: 0,
    game_platform_name: "PragmaticPlay",
    is_try: 2,
    name: "PragmaticPlay",
    params: null,
    gpcode: "PPTF",
    type: 16,
    status: 0,
  },
  {
    abbreviation: "PPTF",
    game_platform_id: 0,
    game_platform_name: "PragmaticPlay",
    is_try: 2,
    name: "PragmaticPlay",
    params: null,
    gpcode: "PPTF",
    type: 2,
    status: 0,
  },
  {
    abbreviation: "PPTF",
    game_platform_id: 0,
    game_platform_name: "PragmaticPlay",
    is_try: 2,
    name: "PragmaticPlay",
    params: null,
    gpcode: "PPTF",
    type: 6,
    status: 0,
  },
  {
    abbreviation: "SABATF",
    game_platform_id: 0,
    game_platform_name: "SABATF",
    is_try: 2,
    name: "SABATF",
    params: null,
    gpcode: "SABATF",
    type: 1,
    status: 0,
  },
];
const providerList = [
  {
    abbreviation: "365",
    transferwallet: false,
    name: "365",
    gpcode: "365",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "BC",
    transferwallet: false,
    name: "BetConstruct",
    gpcode: "BC",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "AVIA",
    transferwallet: true,
    name: "AVIA",
    gpcode: "AVIA",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "JILITF",
    transferwallet: true,
    name: "JILI",
    gpcode: "JILITF",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "AWC_PGS",
    transferwallet: true,
    name: "PGS",
    gpcode: "AWC_PGS",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "AWC_SG",
    transferwallet: true,
    name: "SG",
    gpcode: "AWC_SG",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "NTTF",
    transferwallet: true,
    name: "NTTF",
    gpcode: "NTTF",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "RTTF",
    transferwallet: true,
    name: "RTTF",
    gpcode: "RTTF",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "EVOTF",
    transferwallet: true,
    name: "EVO. RT. NT",
    gpcode: "EVOTF",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "BTI",
    transferwallet: true,
    name: "BTI",
    gpcode: "BTI",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "PPTF",
    transferwallet: true,
    name: "PragmaticPlay",
    gpcode: "PPTF",
    status: 0,
    balance: 0,
  },
  {
    abbreviation: "SABATF",
    transferwallet: true,
    name: "SABATF",
    gpcode: "SABATF",
    status: 3,
    balance: 0,
  },
];

export const idr = {
  gameList,
  providerList,
};
